// Gatsby supports TypeScript natively!
import React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


export const query = graphql`
    query TeamQuery {
        allPeopleJson {
            edges {
                node {
                    name
                    role
                    image {
                        childImageSharp {
                            fluid (maxWidth: 1024, maxHeight: 1024) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    bio
                    twitterUrl
                    linkedInUrl
                }
            }
        }
    }
`

const Team = ({data}) => (
    <Layout>
        <Seo title="KPF.NRW Team"/>
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                        <h1>
                            <span
                                className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              KPF.NRW
            </span>
                            <span
                                className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Team
            </span>
                        </h1>
                        <p className="text-xl text-gray-500">
                            Wir – das Team der KPF.NRW – sind als Wissenschaftlerinnen und Beraterinnen durch unsere
                            langjährigen
                            Erfahrungen in Museen, in Archiven, im Kunsthandel, in der universitären Lehre und in der
                            Aus- und
                            Weiterbildung auf dem Gebiet der Provenienzforschung und der Restitutionspolitik die
                            kompetenten
                            Ansprechpartnerinnen in und für NRW. Wir freuen uns auf Ihre Kontaktaufnahme.
                        </p>
                    </div>
                    <ul
                        role="list"
                        className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
                    >
                        {data.allPeopleJson.edges.map(({node}, i) => (
                            <li key={node.name}>
                                <div className="space-y-6">
                                    <Img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                         fluid={node.image.childImageSharp.fluid} alt=""/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>{node.name}</h3>
                                            <p className="text-tefaf">{node.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </Layout>
)

export default Team
